.offerDesc_section{
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    margin-top: 50px;
    margin-bottom: 60px;
    position: relative;
    width: 100%;
    z-index: 2;
}

.offerImgContainer{
    margin: 0 30px;
    width: calc(100% / 4);
}

.offerImgContainer > img{
    width: 100%;
}