body {
  margin: 0;
  font-family:  'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: calc(100vh + 500px);
  background-color: #f9f9f9;
}

.signature{
    font-family: 'Homemade Apple', sans-serif;
}

.sectionTitle{
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #0067da;
    font-family: "Signika", sans-serif;
}

.elementContainer{
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    max-width: 1245px;
}